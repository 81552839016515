<script lang="tsx">
import type { ComponentOverrideOptions } from '@core-types/components'
import type { DefineComponent, PropType, SlotsType } from 'vue'
import type { InternalFormElementValue } from '@core-types/components/CoreUIForm'
import { BaseUiFormError, BaseUiFormLabel } from '#components'
import type { FormAutocomplete } from '@core-types/form'
import type { Getter } from '@core-types/utility'

export type SelectOption<T> = {
    label: string
    value: T
    disabled?: boolean
}

export type SelectOptionGroup<T> = {
    label: string
    options: SelectOption<T>[]
    disabled?: boolean
}

export type SelectOptions<T> = (SelectOption<T> | SelectOptionGroup<T> | string)[]

export type CoreUiFormSelectProps<T> = {
    /**
     * A custom class for the input.
     * This is not the input element itself but rather the div wrapper that is meant to be styled
     * as the input element. (the wrapper div containing the side icons, the input element, etc.)
     *
     * This class is added to the default class.
     */
    inputClass?: string | Record<string, boolean>
    /**
     * A custom class for the HTML input element.
     * This class is added to the default class.
     */
    inputElClass?: string
    /**
     * The placeholder text for the input.
     */
    placeholder?: string
    /**
     * Whether the input is disabled or not.
     * A disabled input cannot be interacted with & will have a different style.
     */
    disabled?: boolean
    /**
     * Whether the input is required or not.
     */
    required?: boolean | undefined
    /**
     * Whether to hide the marker for required inputs or not.
     */
    hideRequired?: boolean
    /**
     * The label of the input.
     * Will get overwritten by the default slot if present.
     */
    label?: string
    /**
     * Whether not to render the label element.
     * An **`aria-label`** attribute will be used **in case the label element is disabled**.
     */
    noLabelElement?: boolean
    /**
     * Whether to always occupy space for the error message or not.
     * Default is `false` which means that the error message will only occupy space when it is shown.
     */
    showDetails?: boolean
    /**
     * The autocomplete attribute for the input.
     * Has automatic checks for password inputs in dev mode in order not to be forgotten.
     */
    autocomplete?: FormAutocomplete
    /**
     * The options for the select input.
     */
    options: SelectOptions<T> | T[] | undefined
    /**
     * The getter of the value to be used as a label.
     */
    labelGetter?: Getter<T>
    /**
     * The getter of the value to be used as a value.
     */
    valueGetter?: Getter<T>
}

type CoreUiFormSelectSlots<T> = {
    default: {}
    label: {}
    leading: {
        data: ComputedRef<T>
    }
    trailing: {
        data: ComputedRef<T>
    }
    dropdownIcon: {}
    error: {
        error: string | null
    }
}

type ComponentOptions = {
    name: string
}

export function defineComponentCoreUiFormSelect<T>(_options?: ComponentOverrideOptions<ComponentOptions,  {}, CoreUiFormSelectSlots<T>>): DefineComponent<CoreUiFormSelectProps<T>> {
    return defineComponent({
        name: _options?.options?.name ?? 'CoreUiFormSelect',
        props: {
            inputClass: {
                type: [String, Object] as PropType<CoreUiFormSelectProps<T>['inputClass']>,
                default: undefined,
                required: false,
            },
            inputElClass: {
                type: String as PropType<CoreUiFormSelectProps<T>['inputElClass']>,
                default: undefined,
                required: false,
            },
            placeholder: {
                type: String as PropType<CoreUiFormSelectProps<T>['placeholder']>,
                default: undefined,
                required: false,
            },
            disabled: {
                type: Boolean as PropType<CoreUiFormSelectProps<T>['disabled']>,
                default: false,
                required: false,
            },
            required: {
                type: Boolean as PropType<CoreUiFormSelectProps<T>['required']>,
                default: undefined,
                required: false,
            },
            hideRequired: {
                type: Boolean as PropType<CoreUiFormSelectProps<T>['hideRequired']>,
                default: false,
                required: false,
            },
            label: {
                type: String as PropType<CoreUiFormSelectProps<T>['label']>,
                default: undefined,
                required: false,
            },
            noLabelElement: {
                type: Boolean as PropType<CoreUiFormSelectProps<T>['noLabelElement']>,
                default: false,
                required: false,
            },
            showDetails: {
                type: Boolean as PropType<CoreUiFormSelectProps<T>['showDetails']>,
                default: false,
                required: false,
            },
            autocomplete: {
                type: String as PropType<CoreUiFormSelectProps<T>['autocomplete']>,
                default: undefined,
                required: false,
            },
            options: {
                type: Array as PropType<CoreUiFormSelectProps<T>['options']>,
                required: true,
            },
            labelGetter: {
                type: Function as PropType<CoreUiFormSelectProps<T>['labelGetter']>,
                default: undefined,
                required: false,
            },
            valueGetter: {
                type: Function as PropType<CoreUiFormSelectProps<T>['valueGetter']>,
                default: undefined,
                required: false,
            },
            modelValue: {
                type: [String, Number, Object] as PropType<T | null | undefined>,
                default: undefined,
                required: false,
            },
            form: {
                type: Object as PropType<InternalFormElementValue<T | null>>,
                default: undefined,
                required: false,
            },
        },
        slots: Object as SlotsType<CoreUiFormSelectSlots<T>>,
        emits: {
            'update:modelValue': (value: T | null) => true,
            'update:form': (value: InternalFormElementValue<T | null>) => true,
        },
        setup: (props, {
            slots,
            emit,
        }) => {

            if (import.meta.dev && !props.options) {
                console.warn(`[CoreUiFormSelect]: The options for the select were not provided.`)
            }

            const inputValue = computed<T | null | undefined>({
                get() {
                    return props.modelValue as T | null | undefined
                },
                set(val) {
                    emit('update:modelValue', val ?? null)
                },
            })

            const formInputValue = computed<InternalFormElementValue<T | null> | undefined>({
                get() {
                    return props.form
                },
                set(val) {
                    emit('update:form', val!)
                },
            })

            const internalValue = computed<T | null>({
                get() {
                    // make normal `v-model` have higher priority
                    if (inputValue.value !== undefined) return inputValue.value
                    // otherwise use the form input value binding
                    if (formInputValue.value === undefined) console.error('[CoreUiFormSelect]: no v-model value provided')
                    return formInputValue.value?.__v ?? null
                },
                set(value) {
                    if (inputValue.value !== undefined) {
                        inputValue.value = value
                        // do not set form input value if normal `v-model` is used
                        // this is needed to prevent a bug, but I can't remember which one :(
                        return
                    }
                    if (formInputValue.value === undefined) return
                    formInputValue.value.__v = value
                },
            })

            const realInternalValue = computed(() => internalValue.value ? normalizedOptions.value.get(`${internalValue.value}`) : null)


            // TODO: add manual error message if needed
            const errorMessage = computed(() => {
                if (!formInputValue.value) return null
                if (!injected?.formErrors) return null
                // @ts-ignore
                return (injected.formErrors.value[formInputValue.value?.__f] ?? null) as string | null
            })

            const ariaLabel = computed(() => {
                if (!props.noLabelElement) return undefined

                if (slots.default) {
                    return slots.default({})?.[0]?.children?.toString().trim()
                }

                if (props.label) return props.label

                return undefined
            })

            const isLabelElementPresent = computed<boolean>(() => {
                if (props.noLabelElement) return false
                return !!(slots.default?.({})) || !!props.label
            })

            const showErrorDiv = computed<boolean>(() => {
                return !!(errorMessage.value || props.showDetails)
            })

            const isInputRequired = computed<boolean>(() => {
                return props.required ?? formInputValue.value?.__r ?? false
            })

            const { injected } = useCoreUiFormProvide()

            const errorId = `input-error-${useId()}`
            const inputId = `input-id-${useId()}`

            // TODO: REFACTOR
            // Save all options in a map for easier access by value
            const normalizedOptions = computed(() => {
                const map = new Map<unknown, SelectOption<T> | string | T>()
                for (const option of (props.options ?? [])) {
                // if a getter is specified, it has priority
                    if (props.valueGetter) {
                    // @ts-expect-error
                        const value = getValueByGetter(option, props.valueGetter)
                        if (!value) {
                            console.error('[CoreUiFormSelect]: The value getter returned a null value for the option:', option)
                            continue
                        }
                        map.set(value, option as T)
                        continue
                    }


                    if (isOptionGroup(option)) {
                        for (const opt of option.options) {
                            map.set(opt.value as string, opt)
                        }
                    } else {
                        map.set((isOptionObject(option) ? option.value : option) as string, option)
                    }
                }
                return map
            })

            // TODO: refactor
            // TODO: add prop not to auto-select the first option
            // Automatic selection of the first option if no value is selected
            if (internalValue.value === null && normalizedOptions.value.size > 0) {
                const firstOption = normalizedOptions.value.values().next().value
                if (props.valueGetter) {
                    // TODO: fix types
                    internalValue.value = getValueByGetter(firstOption as any, props.valueGetter as any)
                } else if (isOptionObject(firstOption)) {
                    internalValue.value = firstOption.value
                } else {
                    internalValue.value = firstOption as T
                }
            }


            // ---------------------------------------------------------------------------------------------------------

            function isOptionGroup(option: any): option is SelectOptionGroup<T> {
                return typeof option === 'object' && 'options' in option && 'label' in option
            }

            function isOptionObject(option: any): option is SelectOption<T> {
                return typeof option === 'object' && 'value' in option && 'label' in option
            }

            // ..........

            function handleInputChange() {
                if (!injected.bus || !formInputValue.value) return

                injected.bus.emit({
                    type: 'change',
                    __f: formInputValue.value.__f,
                })
            }

            function handleInputBlur() {
                if (!injected.bus || !formInputValue.value) return

                injected.bus.emit({
                    type: 'blur',
                    __f: formInputValue.value.__f,
                })
            }


            function renderValueOrPlaceholder() {
                if (internalValue.value === null) {
                    if (props.placeholder) {
                        return <span class="sim-select__placeholder">
                            { props.placeholder }
                        </span>
                    } else {
                        return <span style="visibility: hidden;">
                            -
                        </span>
                    }
                } else {
                    if (props.labelGetter) {
                        // TODO: fix types
                        return getValueByGetter(realInternalValue.value as any, props.labelGetter as any)
                    }

                    const currentValue = normalizedOptions.value.get(internalValue.value)
                    return typeof currentValue === 'string'
                        ? currentValue
                        : isOptionObject(currentValue)
                            ? currentValue.label ?? null
                            : currentValue
                }
            }

            const parentScope = getParentScope()

            // RENDER FUNCTION
            return () => (
                <div
                    class={[
                        'sim-select',
                        {
                            'sim-select--error': errorMessage.value,
                            'sim-select--disabled': props.disabled,
                        },
                    ]}
                >

                    {
                        isLabelElementPresent.value
                            ? <BaseUiFormLabel
                                for={inputId}
                                required={isInputRequired.value && !props.hideRequired}
                            >
                                {slots.label || _options?.slots?.label ? renderSlot(slots.label, _options?.slots?.label, {}) : props.label}
                            </BaseUiFormLabel>
                            :  null
                    }

                    <div {...{
                        class: [
                            'sim-select__input',
                            {
                                'sim-select__input--left-icon': slots.leading || _options?.slots?.leading,
                                'sim-select__input--right-icon': true, // dropdown is always visible
                            },
                            props.inputClass,
                        ],
                        ...parentScope,
                    }}>
                        {
                            slots.leading || _options?.slots?.leading
                                ? <div class="sim-select__side-content" aria-hidden="true">
                                    {
                                        renderSlot(slots.leading, _options?.slots?.leading, {
                                            data: realInternalValue as ComputedRef<T>,
                                        })
                                    }
                                </div>
                                : null
                        }

                        <div class="sim-select__input-text" aria-hidden="true">
                            { renderValueOrPlaceholder() }
                        </div>

                        <select
                            id={inputId}
                            v-model={internalValue.value}
                            class="sim-select__input-el"
                            disabled={props.disabled}
                            required={isInputRequired.value}
                            aria-label={ariaLabel.value}
                            aria-describedby={showErrorDiv.value ? errorId : undefined}
                            aria-invalid={!!errorMessage.value}
                            autocomplete={props.autocomplete}
                            onBlur={handleInputBlur}
                            onChange={handleInputChange}
                        >
                            {

                                // if there is no item selected, show the placeholder
                                internalValue.value === null && props.placeholder
                                    ? <option value="" selected disabled>
                                        { props.placeholder }
                                    </option>
                                    : null
                            }

                            {(props.options ?? []).map((option, index) => {
                                if (isOptionGroup(option)) {
                                    return (
                                        <optgroup
                                            key={`optgroup-${index}`}
                                            label={option.label}
                                            disabled={option.disabled}
                                        >
                                            {option.options.map((opt, i) => (
                                                <option
                                                    key={`option-${index}-${i}`}
                                                    value={opt.value}
                                                    disabled={opt.disabled}
                                                >
                                                    {opt.label}
                                                </option>
                                            ))}
                                        </optgroup>
                                    )
                                } else {
                                    return (
                                        <option
                                            key={`option-${index}`}
                                            value={
                                                isOptionObject(option)
                                                    ? option.value
                                                    : props.valueGetter
                                                        ? getValueByGetter(option as any, props.valueGetter as any)
                                                        : option
                                            }
                                            disabled={isOptionObject(option) ? option.disabled : undefined}
                                        >
                                            {
                                                isOptionObject(option)
                                                    ? option.label
                                                    : props.labelGetter
                                                        ? getValueByGetter(option as any, props.labelGetter as any)
                                                        : option
                                            }
                                        </option>
                                    )
                                }
                            })}
                        </select>

                        <div class="sim-select__side-content" aria-hidden="true">
                            {
                                slots.trailing || _options?.slots?.trailing
                                    ? <div>
                                        {
                                            renderSlot(slots.trailing, _options?.slots?.trailing, {
                                                data: realInternalValue as ComputedRef<T>,
                                            })
                                        }
                                    </div>
                                    : null
                            }

                            {
                                renderSlot(slots.dropdownIcon, _options?.slots?.dropdownIcon, {}, (
                                    // todo: replace with something actually useful
                                    <div style="width: 24px; height: 24px; background-color: red;"></div>
                                ))
                            }
                        </div>


                    </div>


                    {
                        showErrorDiv.value
                            ? <BaseUiFormError
                                errorId={errorId}
                            >
                                {
                                    renderSlot(slots.error, _options?.slots?.error, { error: errorMessage.value }, () => errorMessage.value)
                                }
                            </BaseUiFormError>
                            : null
                    }

                </div>
            )

        },  // end of setup function

    }) as unknown as DefineComponent<CoreUiFormSelectProps<T>>
}

export default defineComponentCoreUiFormSelect()


</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiFormSelect" as *;

</style>
