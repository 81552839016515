<script lang="tsx">
import { defineComponentCoreUiFormSelect } from '@core/app/components/core/ui/form/CoreUiFormSelect.vue'
import { IconChevron } from '#components'

export default defineComponentCoreUiFormSelect({
    slots: {
        dropdownIcon: () => <IconChevron size="xs" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiFormSelect" as *;

$vertical-padding: 0.85rem;
$side-padding: 1.5rem;

@include select {
    padding: $vertical-padding $side-padding;
    border-radius: 0.5rem;
    gap: 0.625rem;

    background-color: $sim-c-white;
    color: $sim-c-primary-900;
}

.sim-modal {
    @include select {
        background-color: $sim-c-basic-200;
    }
}

@include select--error {
    background-color: $sim-c-white;
    outline: 1px solid $sim-c-danger-700;

    &:focus-within {
        outline-width: 2px;
        //@include accessibility-focus-outline;
        outline-offset: 0;
    }
}

@include select--left-icon {
    padding-left: $vertical-padding;
}

@include select--right-icon {
    padding-right: $vertical-padding;
}

@include placeholder {
    color: $sim-c-basic-800;

    @include sim-text-small;
    @include sim-font-regular;
}

@include side-content {
    color: $sim-c-black;
}

</style>
